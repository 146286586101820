import "lity";
import AOS from 'aos';

export default {
	init() {
		// JavaScript to be fired on all pages
		AOS.init({
			offset: 250,
			delay: 150,
			// once: true,
			// disable: function () {
			// 	var maxWidth = 768;
			// 	return window.innerWidth < maxWidth;
			// }
		});

		$(window).on('resize', function(){
			AOS.refresh();
		});

		// ----- main menu js
		let site_header = '.gdc-header';
		let mega_top = '.gdc-mega--top';
		let mega_trigger = '.gdc-mega--top-label';
		let mega_drop = '.gdc-mega--drop';
		let mega_close = '.gdc-mega--close';

		$(mega_trigger).on('click', function() {
			if ( !$(this).parent(mega_top).hasClass('active-menu-item') ) {
				$('body').addClass('inactive-overflow');
				$(site_header).addClass('active-menu');
				$(mega_top).removeClass('active-menu-item');
				$(this).parent(mega_top).addClass('active-menu-item');
				$(mega_drop).fadeOut();
				$(this).siblings(mega_drop).fadeIn();
			} else if ( $(this).parent(mega_top).hasClass('active-menu-item') ) {
				$('body').removeClass('inactive-overflow');
				$(site_header).removeClass('active-menu');
				$(this).parent(mega_top).removeClass('active-menu-item');
				$(mega_drop).fadeOut();
			}
		});

		$(mega_close).on('click', function() {
			$('body').removeClass('inactive-overflow');
			$(site_header).removeClass('active-menu');
			$(mega_top).removeClass('active-menu-item');
			$(mega_drop).fadeOut();
		});

		// ----- mobile menu js
		let mobile_container = '.gdc-mobile-container';
		let mobile_trigger = '.gdc-mobile--trigger';
		let mobile_top = '.gdc-mobile--top';
		let mobile_top_label = '.gdc-mobile--top-label';
		let mobile_drop = '.gdc-mobile--drop';
		let mobile_back = '.gdc-mobile--back';

		$(mobile_trigger).on('click', function() {
			$(this).toggleClass('active');
			$(site_header).toggleClass('active-menu');
			$('body').toggleClass('inactive-overflow');
			$(mobile_container).toggleClass('active');
		});

		$(mobile_top_label).on('click', function() {
			$(this).parent(mobile_top).addClass('active');
			$(mobile_top_label).addClass('hidden');
		});

		$(mobile_back).on('click', function() {
			$(this).parents(mobile_top).removeClass('active');
			$(mobile_top_label).removeClass('hidden');
		});


		// ----- prevent default for no-link menu items
		$('.no-link > a').click(function(e){
			e.preventDefault();
		});

		// ----- add Lity to a specific button style
		$('.is-style-video-popup').each(function() {
			$(this).children('.wp-block-button__link').attr('data-lity', '');
		});

		// ----- footer form svg
		$('.gdc-footer--form .gform_title').wrapInner('<span></span>')
		$('.gdc-form-arrow').appendTo('.gdc-footer--form .gform_title');
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	}
};